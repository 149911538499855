<template>
    <LiefengContent>
        <template #title>社区架构坐标库管理</template>
        <template #toolsbarRight>
            <Form inline label-colon>
                <FormItem>
                    <!-- <CascaderCity :orgCode="4401" :resetNum="resetNum" @changeCasader="changeCasader" ></CascaderCity> -->
                    <Cascader
                        transfer
                        @on-change="changeCasder"
                        :load-data="loadData"
                        change-on-select
                        style="margin-right: 10px; width: 300px"
                        :data="CascaderList"
                        v-model="CascaderId"
                        placeholder="请选择区/街道/社区"
                    ></Cascader>
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" style="margin-right: 10px; width: 150px" placeholder="小区名称" v-model.trim="projectName" />
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" style="margin-right: 10px; width: 150px" placeholder="楼栋名称" v-model.trim="buildingName" />
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" style="margin-right: 10px; width: 150px" placeholder="房号名称" v-model.trim="houseNum" />
                </FormItem>
                <Button type="primary" style="margin-right: 10px" @click="search">查询</Button>
                <Button type="primary" style="margin-right: 10px" @click="reset">重置</Button>
            </Form>
        </template>
        <template #contentArea>
            <LiefengTable
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :pageSize="pageSize"
                :pagesizeOpts="[20, 30, 50, 100, 200]"
                :total="total"
                :fixTable="true"
                :curPage="page"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            CascaderList: [],
            CascaderId: [],
            //菜单树社区编码
            orgCode: "",
            orgName: "",
            isNum: 0,

            projectName:'',
            buildingName:'',
            houseNum:'',
            tableData: [],
            talbeColumns: [
                {
                    title: "街巷名称",
                    key: "roadName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "小区名称",
                    key: "projectName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "楼栋名称",
                    key: "buildingName",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "房号名称",
                    key: "houseNum",
                    minWidth: 140,
                    align: "center",
                },
                {
                    title: "地址坐标",
                    key: "addr",
                    minWidth: 140,
                    align: "center",
                },

                // {
                //   title: "操作",
                //   width: 140,
                //   align: "center",
                //   render: (h, params) => {
                //     return h("div", [
                //       h(
                //         "Button",
                //         {
                //           props: {
                //             type: "info",
                //             size: "small",
                //           },
                //           style: {
                //             marginLeft: "14px",
                //           },
                //           on: {
                //             click: () => {
                //               this.onDetail(params.row);
                //             },
                //           },
                //         },
                //         "查看"
                //       ),
                //     ]);
                //   },
                // },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,
        }
    },
    methods: {
        // changeCasader(val){
        //         this.cascaderList = val
        //         if(this.resetNum == 0 && this.cascaderList.length == 3){
        //             this.getList()
        //         }
        //     },
        //分页
        hadlePageSize(val) {
            console.log(val)
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        search() {
            this.isNum++
            if (this.CascaderId.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区再进行查询",
                    background: true,
                })
                return
            }
            ;
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        reset(){
            this.projectName = ''
            this.buildingName = ''
            this.houseNum = ''
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        //获取列表
        getList() {
            this.loading = true
            this.$get("/orgzz/pc/region/selectRegionScopePage", {
                communityCode: this.CascaderId[this.CascaderId.length - 1],
                page: this.page,
                pageSize: this.pageSize,
                projectName: this.projectName,
                buildingName: this.buildingName,
                houseNum: this.houseNum,
            }).then(res => {
                if (res.code == 200) {
                    this.loading = false
                    this.tableData = res.dataList
                    this.total = res.maxCount,
                    this.curPage = res.currentPage;
                } else {
                    this.loading = false
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                }
            })
        },
        // 获取级联
        getCascader() {
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: "4401",
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    this.CascaderList = this.forMatterTree(res.dataList)
                    this.CascaderList.map(item => {
                        item.loading = false
                    })
                    if (this.isNum == 0 && this.CascaderList.length != 0) {
                        this.CascaderId.push(this.CascaderList[0].value)
                    }
                    // this.hadlePageSize({
                    //     pageSize: this.pageSize,
                    //     page: this.pageNum,
                    // })
                }
            })
        },
        changeCasder(val, item) {
            if (item.length == 1) {
                // this.$Message.warning({
                //   content:'请选择到社区进行查询',
                //   background:true
                // })
                // return
            } else if (item.length == 2) {
                //  this.$Message.warning({
                //     content:'请选择到社区进行查询',
                //     background:true
                //   })
                //   return
            } else if (item.length == 3) {
                this.orgCode = item[2].orgCode
                this.orgName = item[2].orgName
            }
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName
                data[i].value = data[i].orgCode
                if (data[i].children && data[i].children != null && data[i].children.length > 0) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        loadData(item, callback) {
            item.loading = true
            let params = {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: item.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                ...params,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items => {
                        if (items.orgLevel == 4) {
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children)
                    if (this.isNum == 0 && item.children.length != 0) {
                        this.CascaderId.push(item.children[0].value)
                        this.isNum = 1
                        return
                    } else if (this.isNum == 1 && item.children.length != 0) {
                        this.CascaderId.push(item.children[0].value)
                        this.isNum ++
                        this.orgCode = item.children[0].value
                        this.hadlePageSize({
                            pageSize: this.pageSize,
                            page: this.pageNum,
                            //菜单树社区编码
                            orgCode: this.orgCode,
                        })
                    }
                } else {
                    callback([])
                }
                console.log(this.CascaderList)
            })
        },
    },
    created() {
        this.getCascader()
    },
    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
}
</style>